import { useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import useLoader from 'hooks/useLoader';
import UserDetailsCard from 'modules/UserView';
import UserViewHeader from 'modules/UserView/UserViewHeader';
import { getUserConfig, getUserData } from './utils';

export const UserDetails = () => {
  const { userId } = useParams();
  if (!userId) return null;

  const { data, Loader, updateData } = useLoader(getUserConfig(userId));

  return (
    <Loader>
      {data && (
        <Grid container maxWidth={1024} mx="auto">
          <Grid item xs={12}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <UserViewHeader user={data} fetchData={updateData} />
              <UserDetailsCard userData={getUserData(data)} />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
