import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import useFormMethods from 'hooks/useFormMethods';
import { getRegion } from 'hooks/useGetRegion/utils';
import useSearchInput from 'hooks/useSearchInput';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { AddEstateModal } from 'modules/ModalForm/AddEstateModal';
import { RelationData } from 'modules/Relation/types';
import {
  fetchEstateOptions,
  getPersonsIdsToFilter,
  personToRelation,
} from 'modules/Relation/utils';
import { FetchedEstateListItem } from 'api/estate/types';
import { ControlledSearchInput } from 'components/inputs';
import { fetchEstateOwners } from 'pages/Dossiers/New/utils';
import { EstateOption } from './types';
import { renderEstateOption, setSubHeaderForAssignedParameter } from './utils';

const DossierEstateInput = () => {
  const [isCreatingNewEstate, setIsCreatingNewEstate] = useState(false);
  const { t, i18n } = useTranslation(['common', 'formFields']);

  const { registerController, setValue, getValues, trigger } =
    useFormMethods<DossierFormInterface>();

  const { assignedEstates, setRegion, editableFields, assignedLandlords, mode } =
    useContext(DossierFormContext);

  const contextOptions = useMemo(
    () => setSubHeaderForAssignedParameter(assignedEstates),
    [i18n.language],
  );

  const { searchInputProps, clearQuery, getOptions } = useSearchInput({
    contextOptions,
    fetchOptions: (params) => fetchEstateOptions(params),
    shouldFetchOnInit: !assignedLandlords.length || !assignedEstates.length,
  });

  const getRegionOnEdit = useCallback(async () => {
    const region = await getRegion(
      assignedEstates[0].data.postCode,
      assignedEstates[0].data.city,
    );
    setRegion(region || '');
  }, [assignedEstates]);

  useEffect(() => {
    if (assignedEstates.length && mode === 'edition') {
      getRegionOnEdit();
    }
  }, [mode, getRegionOnEdit]);

  const handleChange = async (option: EstateOption | null) => {
    if (option?.created) {
      setIsCreatingNewEstate(true);
    } else if (option) {
      clearQuery();
      const tenants = getValues('tenants');
      const idsToFilter = getPersonsIdsToFilter(tenants);
      const owners = await fetchEstateOwners(option.data, idsToFilter);
      setValue('landlords', owners.map(personToRelation));
      trigger('landlords');

      const region = await getRegion(option.data.postCode, option.data.city);
      setRegion(region || '');
    }
  };

  const handleAddingNewEstate = async (estate: RelationData<FetchedEstateListItem>) => {
    await getOptions({ query: estate.address });
    setValue('estateId', estate.id);
    const region = await getRegion(estate.postCode, estate.city);
    setRegion(region || '');
  };

  return (
    <>
      <ControlledSearchInput
        {...registerController('estateId')}
        label={t('formFields:estate')}
        renderValue={renderEstateOption}
        onChange={handleChange}
        disabled={!editableFields.estate}
        creatable
        {...searchInputProps}
      />
      <AddEstateModal
        open={isCreatingNewEstate}
        onSubmit={handleAddingNewEstate}
        closeModal={() => {
          setIsCreatingNewEstate(false);
          clearQuery();
        }}
      />
    </>
  );
};

export default DossierEstateInput;
