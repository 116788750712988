import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEstateComposition } from 'formData/estate/details/utils';
import { DisabledSteps } from 'templates/StepWizard/types';
import { EstateFormInterface, EstateStepName } from '../types';

const useEstateDisabledSteps = (stepsIndex: Record<EstateStepName, number>) => {
  const { t } = useTranslation();

  const { control } = useFormContext<EstateFormInterface>();

  const type = useWatch({ control, name: 'estate.type' });
  const destination = useWatch({ control, name: 'estate.destination' });
  const isCompositionDisabled = useMemo(() => {
    // Agriculture does not require providing type
    if (destination === 'Agriculture') {
      return true;
    }
    return !!destination && !!type && !isEstateComposition({ type, destination });
  }, [type, destination]);

  const disabledSteps: DisabledSteps = useMemo(
    () => ({
      [stepsIndex.composition]: isCompositionDisabled
        ? t('tooltips.estateCompositionStepDisabled')
        : false,
    }),
    [t, isCompositionDisabled],
  );

  return disabledSteps;
};

export default useEstateDisabledSteps;
