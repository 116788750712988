import { PropsWithChildren } from 'react';
import { Box, CardActionArea } from '@mui/material';
import CustomLink from 'atoms/Link';
import { ProductNotification } from 'api/notifications/types';

const NotificationWrapper = ({
  children,
  isActionRequired,
}: PropsWithChildren<Pick<ProductNotification, 'isActionRequired'>>) =>
  isActionRequired ? (
    <CardActionArea
      sx={{ m: -2, p: 2, a: { textDecoration: 'none' } }}
      disabled={!isActionRequired}
    >
      <CustomLink to="">
        <Box display="flex" alignItems="center" gap={2}>
          {children}
        </Box>
      </CustomLink>
    </CardActionArea>
  ) : (
    <>{children}</>
  );

export default NotificationWrapper;
