import {
  AsYouType,
  CountryCode,
  getExampleNumber,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/mobile/examples';
import getLng from 'utils/getLng';
import { Country } from 'api/config/types';
import { SelectOption } from '../Select/types';

export const getCountryCode = (code: string, number: string) => {
  const phoneNumber = `${code} ${number}`;
  if (isPossiblePhoneNumber(phoneNumber)) {
    return parsePhoneNumber(phoneNumber).country;
  }
};

export const formatPhone = (input: string, code: string) => {
  const asYouType = new AsYouType();
  if (!code) {
    return asYouType.input(input);
  }

  const formatted = asYouType.input(`${code} ${input}`);
  return formatted.slice(code.length + 1).trim();
};

export const getPhonePlaceholder = (countryId: CountryCode) =>
  getExampleNumber(countryId, examples)
    ?.formatInternational()
    .slice(countryId.length + 1)
    .trim();

export const mapPhoneCodes = ({
  alpha2Code,
  phoneCode,
  name,
}: Country): SelectOption<CountryCode> => ({
  code: phoneCode,
  label: name[getLng()],
  value: alpha2Code,
  suffix: phoneCode,
});
