import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { CheckIcon } from 'icons';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';

const EstateElectricityCard = ({
  electricity,
}: Pick<EstateViewInterface['data'], 'electricity'>) => {
  const { t } = useTranslation(['formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow
        asHeader
        title={t('formFields:electricity.self')}
        value={<CheckIcon />}
        testId="electricity"
      />
      {electricity?.type && (
        <CardRow
          title={t('formFields:electricity.type')}
          value={t(`enums:estateElectricityTypes.${electricity.type}`)}
          testId="electricityType"
        />
      )}
      {electricity?.eanNumber && (
        <CardRow
          title={t('formFields:electricity.eanNumber')}
          value={electricity.eanNumber}
          testId="electricityEanNumber"
        />
      )}
      {electricity?.meterNumber && (
        <CardRow
          title={t('formFields:electricity.meterNumber', {
            suffix: electricity.meterNumber2 ? 1 : undefined,
          })}
          value={electricity.meterNumber}
          testId="electricityMeterNumber"
        />
      )}
      {electricity?.meterNumber2 && (
        <CardRow
          title={t('formFields:electricity.meterNumber2')}
          value={electricity.meterNumber2}
          testId="electricityMeterNumber2"
        />
      )}
    </Card>
  );
};

export default EstateElectricityCard;
