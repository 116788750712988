import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import LoadingElement from 'atoms/LoadingElement';

interface Props {
  fetchRecords: () => Promise<void>;
  disabled?: boolean;
}

const ShowMoreRecordsButton = ({ fetchRecords, disabled }: Props) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onClick = async () => {
    setLoading(true);
    await fetchRecords();
    setLoading(false);
  };

  return (
    <Box alignSelf="center">
      {loading ? (
        <LoadingElement />
      ) : (
        <Button role="text" color="secondary" onClick={onClick} disabled={disabled}>
          {t('showMore')}
        </Button>
      )}
    </Box>
  );
};

export default ShowMoreRecordsButton;
