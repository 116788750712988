import { Badge, Box, CircularProgress, IconButton, Menu } from '@mui/material';
import { useNotificationsMenu, useNotificationsTab } from 'hooks/useNotifications';
import { NotificationIcon } from 'icons';
import NotificationsFooter from './NotificationsFooter';
import NotificationsHeader from './NotificationsHeader';
import NotificationsList from './NotificationsList';

const NotificationsMenu = () => {
  // TODO: Hooks for NotificationsMenu can be moved to NotificationsMenu/hooks folder
  // After updating notification interface, some of them won't be needed anymore and the rest should be transformed
  const {
    loading,
    notifications,
    totalCount,
    menuProps,
    openMenu,
    closeMenu,
    fetchNotificationsSummary,
  } = useNotificationsMenu();

  const { activeTab, isFetchingInitially, notificationsByType, setActiveTab } =
    useNotificationsTab(notifications, loading);

  return (
    <>
      <Badge
        color="secondary"
        badgeContent={totalCount}
        overlap="circular"
        sx={{ '.MuiBadge-badge': { color: 'white' } }}
        data-testid={'notification-badge'}
      >
        <IconButton onClick={openMenu} sx={({ mixins }) => mixins.headerIconButton}>
          <NotificationIcon fontSize="small" />
        </IconButton>
      </Badge>
      <Menu {...menuProps} onClose={closeMenu} sx={({ mixins }) => mixins.headerMenu}>
        <Box width="400px">
          <NotificationsHeader
            activeTab={isFetchingInitially ? false : activeTab}
            setActiveTab={setActiveTab}
          />
          {!loading ? (
            // TODO: After updating notification interface, NotificationsList can be replaced with mapping of SingleNotification component
            <NotificationsList
              activeTab={activeTab}
              notifications={notificationsByType}
              closeNotifications={closeMenu}
              fetchNotificationsSummary={fetchNotificationsSummary}
            />
          ) : (
            <Box display="flex" justifyContent="center" sx={{ px: 2, py: 1 }}>
              <CircularProgress />
            </Box>
          )}
          <NotificationsFooter
            activeTab={activeTab}
            notifications={notificationsByType}
            closeNotifications={closeMenu}
            fetchNotificationsSummary={fetchNotificationsSummary}
          />
        </Box>
      </Menu>
    </>
  );
};

export default NotificationsMenu;
