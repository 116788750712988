import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import StatusIndicator from 'atoms/StatusIndicator';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';
import EstateTypeRow from './EstateTypeRow';

const EstateBasicDataCard = ({
  data: { destination, type, subType, floorLevel },
  status,
  ownReference,
}: Pick<EstateViewInterface, 'status' | 'ownReference' | 'data'>) => {
  const { t } = useTranslation(['common', 'formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.estate.basicInfo')} />
      <CardRow
        title={t('formFields:status')}
        value={
          <StatusIndicator
            status={t(`enums:estateStatus.${status}`)}
            color={status === 'Active' ? 'success.main' : 'grey.400'}
            size="small"
          />
        }
        testId="status"
      />
      {ownReference && (
        <CardRow title={t('formFields:ownReference')} value={ownReference} />
      )}
      <CardRow
        title={t('formFields:destination')}
        value={t(`enums:estateDestination.${destination}`)}
        testId="destination"
      />
      <EstateTypeRow type={type} subType={subType} />
      {floorLevel?.toString() && (
        <CardRow
          title={t('formFields:floorLevel')}
          value={floorLevel.toString()}
          testId="floorLevel"
        />
      )}
    </Card>
  );
};

export default EstateBasicDataCard;
