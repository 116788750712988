import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import useListRecords from 'hooks/useListRecords';
import FilterChips from 'templates/FilterChips';
import { FilterChipInterface } from 'templates/FilterChips/types';
import { formatDate } from 'utils/date';
import { SortDirection } from 'utils/sortUtils/types';
import { DateRangeValues } from 'components/DateRange/types';
import ShowMoreRecordsButton from 'components/ShowMoreRecordsButton';
import HistoryOfChangesRecord from './HistoryOfChangesRecord';
import HistoryOfChangesToolbar from './HistoryOfChangesToolbar';
import { HistoryOfChangesProps } from './types';
import { mapHistoryOfChanges } from './utils';

const HistoryOfChanges = ({
  entityId,
  getHistory,
  activities,
}: HistoryOfChangesProps) => {
  const { t, i18n } = useTranslation('history');

  const [eventsData, setEventsData] = useState<FilterChipInterface[]>(
    activities.map((name) => ({
      name,
      label: t(`activities.${name}`, name),
      checked: false,
    })),
  );
  const [dateRange, setDateRange] = useState<DateRangeValues>([null, null]);
  const [sortBy, setSortBy] = useState<SortDirection>('desc');

  const getRecords = useCallback(
    (recordsCount: number) =>
      getHistory(entityId, {
        limit: recordsCount,
        sort: sortBy ? `modifiedAt:${sortBy}` : undefined,
        eventsData: eventsData
          .filter(({ checked }) => checked)
          .map(({ name }) => name)
          .join(','),
        dateFrom: formatDate(dateRange[0], 'yyyy-MM-dd'),
        dateTo: formatDate(dateRange[1], 'yyyy-MM-dd'),
      }),
    [entityId, i18n.language, eventsData, dateRange, sortBy],
  );

  const {
    fetchRecords,
    fetchAdditionalRecords,
    records,
    isFetchingRecords,
    totalRecordsCount,
    visibleRecordsCount,
  } = useListRecords({ getRecords });

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <HistoryOfChangesToolbar
        sortBy={sortBy}
        setSortBy={setSortBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <FilterChips filters={eventsData} setFilters={setEventsData} />
      {isFetchingRecords ? (
        <LoadingElement />
      ) : records.length ? (
        <>
          <Card sx={({ mixins }) => mixins.pageCard}>
            {mapHistoryOfChanges(records).map((record) => (
              <HistoryOfChangesRecord
                key={`${record.propertyName}_${record.modifiedAt}`}
                {...record}
              />
            ))}
          </Card>
          <ShowMoreRecordsButton
            fetchRecords={fetchAdditionalRecords}
            disabled={visibleRecordsCount >= totalRecordsCount}
          />
        </>
      ) : (
        <Typography variant="h5" textAlign="center">
          {totalRecordsCount ? t('noHistoryWithFiltersLabel') : t('noHistoryLabel')}
        </Typography>
      )}
    </Box>
  );
};

export default HistoryOfChanges;
