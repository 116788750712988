import { useState } from 'react';
import { Stack } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { RemoveIcon } from 'icons';
import RelationDetails from './RelationDetails';
import { RelationData } from './types';

interface Props<T extends object> {
  handleRemove: (relation: RelationData<T>) => void;
  relation: RelationData<T>;
  removable?: boolean;
  notRemovableReason?: string;
  showIcon?: boolean;
  withLink?: boolean;
}

const RelationPreviewItem = <T extends object>({
  handleRemove,
  relation,
  removable = true,
  notRemovableReason,
  showIcon,
  withLink,
}: Props<T>) => {
  const [removing, setRemoving] = useState(false);

  const onRemove = (relation: RelationData<T>) => async () => {
    setRemoving(true);
    await handleRemove(relation);
    setRemoving(false);
  };

  return (
    <Stack
      direction="row"
      minWidth={200}
      gap={2}
      py={1}
      alignItems="center"
      justifyContent="space-between"
      data-testid={`relation-${relation.id}`}
    >
      <RelationDetails
        relation={relation}
        isPreview
        showIcon={showIcon}
        withLink={withLink}
      />
      <TooltipIconButton
        Icon={<RemoveIcon />}
        onClick={onRemove(relation)}
        disabled={!removable || removing}
        tooltipTitle={notRemovableReason}
      />
    </Stack>
  );
};
export default RelationPreviewItem;
