import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { ChangeStepHandler, SaveStepperHandler } from 'templates/StepWizard/types';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { NaturalPersonFormProps, NaturalPersonStepName } from '../types';
import useNaturalPersonStepsErrors from './useNaturalPersonStepsErrors';
import useSubmitNaturalPersonForm from './useSubmitNaturalPersonForm';

interface Props {
  onSaveSuccess: NaturalPersonFormProps['handleSave'];
  stepsIndex: Record<NaturalPersonStepName, number>;
}

const useNaturalPersonSteps = ({ onSaveSuccess, stepsIndex }: Props) => {
  const stepsErrors = useNaturalPersonStepsErrors(stepsIndex);
  const submitForm = useSubmitNaturalPersonForm({ onSaveSuccess });

  const { handleSubmit } = useFormContext<NaturalPersonFormData>();
  const { isAnyDocumentEmpty, markEmptyDocuments } = useContext(FilesUploadContext);

  const onSave: SaveStepperHandler = (event) => handleSubmit(submitForm())(event);

  const changeStep: ChangeStepHandler = (event, changeStep, currentStep) =>
    handleSubmit(() =>
      currentStep === stepsIndex.documents && isAnyDocumentEmpty
        ? markEmptyDocuments()
        : changeStep(),
    )(event);

  return {
    stepsErrors,
    onNext: changeStep,
    onLabel: changeStep,
    onBack: changeStep,
    onSave,
  };
};

export default useNaturalPersonSteps;
