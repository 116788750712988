import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Checkbox, Typography } from '@mui/material';
import LoadingElement from 'atoms/LoadingElement';
import useListRecords from 'hooks/useListRecords';
import { FilterChipInterface } from 'templates/FilterChips/types';
import { getNotifications } from 'api/notifications/requests';
import { DateRangeValues } from 'components/DateRange/types';
import ShowMoreRecordsButton from 'components/ShowMoreRecordsButton';
import NotificationContent from './NotificationContent';
import NotificationsToolbar from './NotificationsToolbar';
import { NOTIFICATIONS_FILTERS } from './static-data';
import { NotificationType } from './types';
import useNotificationsToDelete from './useNotificationsToDelete';

const NotificationsList = () => {
  const { t, i18n } = useTranslation(['common', 'enums']);

  const [notificationType, setNotificationType] = useState<NotificationType | null>(null);
  const [dateRange, setDateRange] = useState<DateRangeValues>([null, null]);
  const [filters, setFilters] = useState<FilterChipInterface[]>(
    NOTIFICATIONS_FILTERS.map((name) => ({
      name,
      label: t(`enums:notificationFilters.${name}`, name),
      checked: false,
    })),
  );

  const getRecords = useCallback(
    (recordsCount: number) =>
      getNotifications({
        limit: recordsCount,
        // TODO: Handle filtering - pass params here and into dependencies
      }),
    [i18n.language],
  );

  const {
    records,
    fetchRecords,
    fetchAdditionalRecords,
    isFetchingRecords,
    totalRecordsCount,
    visibleRecordsCount,
  } = useListRecords({
    getRecords,
    initVisibleRecordsCount: 25,
    additionalRecordsToLoadCount: 10,
  });

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const {
    notificationsToDelete,
    selectNotificationToDelete,
    deleteSelectedNotifications,
  } = useNotificationsToDelete(fetchRecords);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <NotificationsToolbar
        dateRange={dateRange}
        setDateRange={setDateRange}
        filters={filters}
        setFilters={setFilters}
        notificationType={notificationType}
        setNotificationType={setNotificationType}
        notificationsToDelete={notificationsToDelete}
        deleteSelectedNotifications={deleteSelectedNotifications}
      />
      {isFetchingRecords ? (
        <LoadingElement />
      ) : records.length ? (
        <>
          <Card sx={({ mixins }) => mixins.pageCard}>
            {records.map((notification) => (
              <Box key={notification.id} sx={({ mixins }) => mixins.cardRow}>
                <Checkbox onChange={() => selectNotificationToDelete(notification.id)} />
                <NotificationContent {...notification} />
              </Box>
            ))}
          </Card>
          <ShowMoreRecordsButton
            fetchRecords={fetchAdditionalRecords}
            disabled={visibleRecordsCount >= totalRecordsCount}
          />
        </>
      ) : (
        <Typography variant="h5" textAlign="center">
          {totalRecordsCount
            ? t('common:notifications.noNotificationsWithFilters')
            : t('common:notifications.noNotifications')}
        </Typography>
      )}
    </Box>
  );
};

export default NotificationsList;
