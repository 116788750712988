import { Navigate, RouteObject } from 'react-router-dom';
import App from 'App';
import ErrorElement from 'atoms/ErrorElement';
import {
  BackofficePermissions,
  DashboardPermissions,
  DossierPermissions,
  EstatePermissions,
  PersonPermissions,
  UserPermissions,
} from 'consts/permissions';
import BusinessesList from 'pages/Businesses/List';
import Dashboard from 'pages/Dashboard';
import DossierDetails from 'pages/Dossiers/Details';
import DossierEdition from 'pages/Dossiers/Edition/DossierEdition';
import DossiersList from 'pages/Dossiers/List';
import AddNewDossier from 'pages/Dossiers/New';
import EstateDetails from 'pages/Estates/Details';
import EstateEdition from 'pages/Estates/Edition/EstateEdition';
import EstatesList from 'pages/Estates/List';
import AddNewEstate from 'pages/Estates/New';
import FaqManagement from 'pages/FaqManagement';
import MigrationList from 'pages/MigrationQueue/List/MigrationList';
import Notifications from 'pages/Notifications';
import LegalPersonDetails from 'pages/Persons/LegalPersons/Details';
import LegalPersonEdition from 'pages/Persons/LegalPersons/Edition';
import PersonsList from 'pages/Persons/List';
import NaturalPersonDetails from 'pages/Persons/NaturalPersons/Details';
import NaturalPersonEdition from 'pages/Persons/NaturalPersons/Edition';
import AddNewPerson from 'pages/Persons/NewPerson';
import Settings from 'pages/Settings';
import Translations from 'pages/Translations';
import UserDetails from 'pages/Users/Details';
import UserEdition from 'pages/Users/Edition/UserEdition';
import UsersList from 'pages/Users/List';
import AddNewUser from 'pages/Users/New';
import Version from 'pages/Versions';
import ProtectedRoute from './ProtectedRoute';
import routes from './routes';
import { dossierTabs, estateTabs, legalPersonTabs, naturalPersonTabs } from './tabRoutes';
import {
  redirectAdminOnlyOrNotImpersonated,
  redirectAdmins,
  redirectNotImpersonated,
} from './utils';

export const mainRoute: RouteObject = {
  path: routes.dashboard,
  element: <App />,
  errorElement: <Navigate to={routes.dashboard} />,
  children: [
    {
      path: routes.dashboard,
      element: (
        <ProtectedRoute
          permission={DashboardPermissions.Read}
          fallback={routes.businesses}
          redirect={redirectAdmins}
        >
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.estates,
      element: (
        <ProtectedRoute
          permission={EstatePermissions.Read}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <EstatesList />
        </ProtectedRoute>
      ),
    },
    {
      id: 'estate',
      path: routes.estate(':estateId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={EstatePermissions.Read}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <EstateDetails />
        </ProtectedRoute>
      ),
      children: estateTabs,
    },
    {
      id: 'estate_edition',
      path: routes.estateEdition(':estateId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={EstatePermissions.Write}
          fallback={routes.estates}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <EstateEdition />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.newEstate,
      element: (
        <ProtectedRoute
          permission={EstatePermissions.Write}
          fallback={routes.estates}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <AddNewEstate />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.persons,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Read}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <PersonsList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.newPerson,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Write}
          fallback={routes.persons}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <AddNewPerson />
        </ProtectedRoute>
      ),
    },
    {
      id: 'naturalPerson',
      path: routes.naturalPerson(':naturalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Read}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <NaturalPersonDetails />
        </ProtectedRoute>
      ),
      children: naturalPersonTabs,
    },
    {
      id: 'naturalPerson_edition',
      path: routes.naturalPersonEdition(':naturalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Write}
          fallback={routes.persons}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <NaturalPersonEdition />
        </ProtectedRoute>
      ),
    },
    {
      id: 'legalPerson',
      path: routes.legalPerson(':legalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Read}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <LegalPersonDetails />
        </ProtectedRoute>
      ),
      children: legalPersonTabs,
    },
    {
      id: 'legalPerson_edition',
      path: routes.legalPersonEdition(':legalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={PersonPermissions.Write}
          fallback={routes.persons}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <LegalPersonEdition />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.dossiers,
      element: (
        <ProtectedRoute
          permission={DossierPermissions.Read}
          redirect={redirectNotImpersonated}
        >
          <DossiersList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.newDossier,
      element: (
        <ProtectedRoute
          permission={DossierPermissions.Write}
          fallback={routes.dossiers}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <AddNewDossier />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.rentalDossierEdition(':dossierId'),
      element: (
        <ProtectedRoute
          permission={DossierPermissions.Write}
          fallback={routes.dossiers}
          redirect={redirectAdminOnlyOrNotImpersonated}
        >
          <DossierEdition />
        </ProtectedRoute>
      ),
    },
    {
      id: 'dossier',
      path: routes.rentalDossier(':dossierId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute
          permission={DossierPermissions.Read}
          redirect={redirectNotImpersonated}
        >
          <DossierDetails />
        </ProtectedRoute>
      ),
      children: dossierTabs,
    },
    {
      path: routes.users,
      element: (
        <ProtectedRoute permission={UserPermissions.Write} redirect={redirectAdmins}>
          <UsersList />
        </ProtectedRoute>
      ),
    },
    {
      id: 'user',
      path: routes.user(':userId'),
      element: (
        <ProtectedRoute permission={UserPermissions.Write} redirect={redirectAdmins}>
          <UserDetails />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorElement,
    },
    {
      id: 'user-edition',
      path: routes.userEdition(':userId'),
      element: (
        <ProtectedRoute permission={UserPermissions.Write} redirect={redirectAdmins}>
          <UserEdition />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorElement,
    },
    {
      path: routes.newUser,
      element: (
        <ProtectedRoute permission={UserPermissions.Write} redirect={redirectAdmins}>
          <AddNewUser />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.settings,
      element: (
        <ProtectedRoute featureFlag="ThirdPartySoftware" redirect={redirectAdmins}>
          <Settings />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.businesses,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <BusinessesList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.migrationQueue,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <MigrationList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.translations,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <Translations />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.versions,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <Version />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.notifications,
      element: (
        <ProtectedRoute permission={'BusinessDataRead'} redirect={redirectAdmins}>
          <Notifications />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.faqManagement,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <FaqManagement />
        </ProtectedRoute>
      ),
    },
  ],
};
