import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import Button from 'atoms/Button';
import FilterChips from 'templates/FilterChips';
import { FilterChipInterface } from 'templates/FilterChips/types';
import { SetState } from 'types/setState';
import DateRange from 'components/DateRange';
import { DateRangeValues } from 'components/DateRange/types';
import { Select } from 'components/inputs';
import { notificationTypeOptions } from './static-data';
import { NotificationType } from './types';

interface Props {
  notificationType: NotificationType | null;
  setNotificationType: SetState<NotificationType | null>;
  dateRange: DateRangeValues;
  setDateRange: SetState<DateRangeValues>;
  filters: FilterChipInterface[];
  setFilters: SetState<FilterChipInterface[]>;
  notificationsToDelete: string[];
  deleteSelectedNotifications: () => void;
}

const NotificationsToolbar = ({
  notificationType,
  setNotificationType,
  dateRange,
  setDateRange,
  filters,
  setFilters,
  notificationsToDelete,
  deleteSelectedNotifications,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12} sm={6} lg={3}>
          <Select
            label={t('selectInput.label.notificationType')}
            placeholder={t('selectInput.placeholder.notificationType')}
            options={notificationTypeOptions}
            value={notificationType ?? ''}
            onChange={setNotificationType}
            clearable
            onClear={() => setNotificationType(null)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DateRange
            label={t('selectInput.label.dateRange')}
            placeholder={t('selectInput.placeholder.dateRange')}
            values={dateRange}
            setValues={setDateRange}
          />
        </Grid>
      </Grid>
      <Box display="flex" gap={3} alignItems="center" justifyContent="space-between">
        <FilterChips filters={filters} setFilters={setFilters} />
        <Button
          role="unsafe"
          disabled={!notificationsToDelete.length}
          onClick={deleteSelectedNotifications}
        >
          {t('notifications.deleteNotificationsLabel')}
        </Button>
      </Box>
    </>
  );
};

export default NotificationsToolbar;
