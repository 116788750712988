import { useCallback, useState } from 'react';
import { FetchedTableData } from 'templates/Table/types';
import { ApiReturn } from 'api/types';

interface Props<TData extends object> {
  /**
   * Method used to fetchRecords. It should be wrapped with _useCallback_.
   * @param recordsCount - limit of fetched records
   */
  getRecords: (recordsCount: number) => Promise<ApiReturn<FetchedTableData<TData>>>;
  /**
   * Initial count of fetched records
   * @default 10
   */
  initVisibleRecordsCount?: number;
  /**
   * Count added to current _visibleRecordsCount_ to fetch additional records
   * @default 10
   */
  additionalRecordsToLoadCount?: number;
}

const useListRecords = <TData extends object>({
  getRecords,
  initVisibleRecordsCount = 10,
  additionalRecordsToLoadCount = 5,
}: Props<TData>) => {
  const [isFetchingRecords, setIsFetchingRecords] = useState(true);
  const [visibleRecordsCount, setVisibleRecordsCount] = useState(initVisibleRecordsCount);
  const [records, setRecords] = useState<TData[]>([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  const fetchRecords = useCallback(
    async (recordsCount = visibleRecordsCount, withSpinner = true) => {
      setIsFetchingRecords(withSpinner);
      const { ok, response } = await getRecords(recordsCount);
      if (ok) {
        setVisibleRecordsCount(recordsCount);
        setTotalRecordsCount(response.meta.total);
        setRecords(response.data);
      }
      setIsFetchingRecords(false);
    },
    [getRecords],
  );

  const fetchAdditionalRecords = async () => {
    const recordsCount = visibleRecordsCount + additionalRecordsToLoadCount;
    await fetchRecords(recordsCount, false);
  };

  return {
    records,
    totalRecordsCount,
    visibleRecordsCount,
    isFetchingRecords,
    fetchRecords,
    /**
     * Fetches additional records - count provided in props as _additionalRecordsToLoadCount_
     */
    fetchAdditionalRecords,
  };
};

export default useListRecords;
