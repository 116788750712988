import { useContext, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { DossierFormInterface } from 'modules/DossierForm/types';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { MAX_RESPONSIBLE_USERS_COUNT } from 'modules/Relation/static-data';
import { fetchResponsibleUsersOptions } from 'modules/Relation/utils';
import { getErrorMessage } from 'components/inputs/utils';

const ResponsibleUsersInput = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<DossierFormInterface>();
  const { mode } = useContext(DossierFormContext);

  const { userId } = useContext(AccountContext);

  const responsibleUsers = useWatch({ control, name: 'responsibleUsers' });

  const {
    fields: previewList,
    append,
    remove,
  } = useFieldArray({ control, name: 'responsibleUsers' });

  const usersToFilter = useMemo(
    () => [...responsibleUsers.map(({ id }) => id), userId],
    [responsibleUsers.length, userId],
  );

  return (
    <Box>
      <AddRelation
        translationKey="responsibleUser"
        // we have to subtract one as backend adds creator of dossier automatically
        maxRelations={MAX_RESPONSIBLE_USERS_COUNT - 1}
        relationsToFilter={usersToFilter}
        fetchOptions={fetchResponsibleUsersOptions}
        errorMessage={getErrorMessage(errors.responsibleUsers)}
        creatable={false}
        onSelectOption={append}
        withHelperText={mode === 'creation'}
      />
      <RelationPreviewList
        relationsToPreview={previewList}
        onRemove={(_, index) => remove(index)}
      />
    </Box>
  );
};

export default ResponsibleUsersInput;
