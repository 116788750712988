import { StepInterface } from 'templates/StepWizard/types';
import {
  NaturalPersonAffiliationsStep,
  NaturalPersonDocumentsStep,
  NaturalPersonGeneralStep,
} from './steps';

export const NATURAL_PERSON_STEP_NAME = {
  general: 'general',
  affiliations: 'affiliations',
  documents: 'documents',
} as const;

export const NATURAL_PERSON_STEPS: StepInterface[] = [
  {
    Component: NaturalPersonGeneralStep,
    name: NATURAL_PERSON_STEP_NAME.general,
    required: true,
  },
  {
    Component: NaturalPersonAffiliationsStep,
    name: NATURAL_PERSON_STEP_NAME.affiliations,
  },
  { Component: NaturalPersonDocumentsStep, name: NATURAL_PERSON_STEP_NAME.documents },
];
