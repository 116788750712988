import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import CardRow, { AffiliatedPersonRow } from 'templates/CardRow';
import { AffiliatedPersonInterface } from 'api/common/types';

interface Props {
  title: 'representativeOf' | 'representedBy' | 'owners' | 'tenants' | 'landlords';
  persons: AffiliatedPersonInterface[];
}

const AffiliatedPersonsCard = ({ persons, title }: Props) => {
  const { t } = useTranslation();

  return persons.length ? (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow
        asHeader
        title={t(`pageCard.affiliatedPersons.${title}`, title)}
        testId={title}
      />
      {persons.map((person) => (
        <AffiliatedPersonRow key={person.id} {...person} />
      ))}
    </Card>
  ) : null;
};

export default AffiliatedPersonsCard;
