import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { formatDate } from 'utils/date';
import HistoryOfChangesIcon from './HistoryOfChangesIcon';
import { HistoryOfChangesInterface } from './types';
import {
  getHistoryPropertyValue,
  getHistoryRecordSubtitle,
  getHistoryTranslationKey,
} from './utils';

const HistoryOfChangesRecord = ({
  modifiedBy,
  eventType,
  eventData,
  modifiedAt,
  propertyName,
  propertyValue,
  propertyValues,
}: HistoryOfChangesInterface) => {
  const { t } = useTranslation(['history', 'enums']);

  const translationKey = getHistoryTranslationKey(propertyName);

  return (
    <Box sx={({ mixins }) => mixins.cardRow}>
      <HistoryOfChangesIcon eventType={eventType} />
      <Box display="flex" flexDirection="column" gap={0.25}>
        <Typography variant="h5">
          {t(`${translationKey}.label`, {
            defaultValue: propertyName,
            context: eventData,
            subtitle: getHistoryRecordSubtitle(propertyName),
          })}
        </Typography>
        <Typography>
          <Trans
            t={t}
            // @ts-ignore i18nKey does not accept propertyName as string, but it works
            i18nKey={`${translationKey}.${eventType}`}
            context={eventData}
            defaults={propertyValue ?? ''}
            values={{
              propertyValue: getHistoryPropertyValue(
                propertyName,
                propertyValue,
                propertyValues,
              ),
            }}
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            components={{
              bold: <Typography fontWeight={600} component="span" />,
            }}
          />
        </Typography>
        <Typography color="grey.500" variant="caption">
          {t('dateAndModifier', {
            modifiedAt: formatDate(modifiedAt, 'dd/MM/yyyy HH:mm'),
            modifiedBy,
            interpolation: { escapeValue: false },
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default HistoryOfChangesRecord;
