import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@mui/material';
import { formatDate } from 'utils/date';
import { ProductNotification } from 'api/notifications/types';
import NotificationWrapper from './NotificationWrapper';

const NotificationContent = ({
  title,
  isActionRequired,
  timestamp,
}: ProductNotification) => {
  const { t } = useTranslation(['common', 'enums']);

  return (
    <NotificationWrapper isActionRequired={isActionRequired}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h5">{title}</Typography>
          {isActionRequired && (
            <Chip
              label={t('enums:notificationTypes.ActionRequired')}
              size="small"
              color="warning"
              sx={{ cursor: 'pointer' }}
            />
          )}
        </Box>
        {/* TODO: Display description of the notification */}
        <Typography>Some short description of the notification</Typography>
        <Typography variant="caption" color="grey.500">
          {formatDate(timestamp, 'dd/MM/yyyy HH:mm')}
        </Typography>
      </Box>
      {isActionRequired && (
        <Box ml="auto">
          <Typography
            fontWeight="bold"
            textAlign="center"
            sx={({ mixins }) => mixins.link}
          >
            {t('common:notifications.viewDossierDetails')}
          </Typography>
        </Box>
      )}
    </NotificationWrapper>
  );
};

export default NotificationContent;
