import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { StepsErrors } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import { hasObjectAnyProperty } from 'utils/object';
import { DossierFormInterface, DossierStepName } from '../types';

const useDossierStepsErrors = (stepsIndex: Record<DossierStepName, number>) => {
  const [stepperErrors, setStepperErrors] = useState<StepsErrors>(null);

  const {
    formState: { errors },
  } = useFormContext<DossierFormInterface>();

  const { checklistItems, dossierType, ...parametersErrors } = errors ?? {};

  useUpdateEffect(() => {
    const isError = !!checklistItems || !!dossierType;
    setStepperErrors((prev) => ({ ...prev, [stepsIndex.checklist]: isError }));
  }, [checklistItems, dossierType]);

  useUpdateEffect(() => {
    const isError = hasObjectAnyProperty(parametersErrors);
    setStepperErrors((prev) => ({ ...prev, [stepsIndex.parameters]: isError }));
  }, [hasObjectAnyProperty(parametersErrors)]);

  return stepperErrors;
};

export default useDossierStepsErrors;
