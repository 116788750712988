import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
} from 'libphonenumber-js/max';
import yup from 'utils/yup';
import { getCountryCode } from 'components/inputs/PhoneInput/utils';

const phoneSchema = yup
  .object({
    number: yup
      .string()
      .default('')
      .when('code', {
        is: '+32',
        then: (schema) =>
          schema.transform((value: string) =>
            value.startsWith('0') ? value.slice(1) : value,
          ),
      }),
    code: yup.string().default('+32'), //removing default here causes TS error in getCountryCode
    countryId: yup.string<CountryCode>(),
  })
  .label('phone')
  .test('phone-validation', (value, { createError }) => {
    if (value?.number) {
      const phoneNumber = `${value.code} ${value.number}`;
      const country = getCountryCode(value.code, value.number);
      const isValid = isValidPhoneNumber(phoneNumber, country);
      let error = validatePhoneNumberLength(phoneNumber, country);

      if (!error && value.countryId && country !== value.countryId) {
        error = 'INVALID_COUNTRY';
      }

      if (!isValid || error) {
        switch (error) {
          case 'INVALID_COUNTRY':
            return createError({
              type: 'invalid-country',
              message: { key: 'phone.invalidCountry' },
            });
          default:
            return createError({
              type: 'invalid-number',
              message: { key: 'phone.invalidNumber' },
            });
        }
      }

      const numberType = parsePhoneNumberFromString(phoneNumber)?.getType();
      if (
        isValid &&
        !(numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE')
      ) {
        return createError({
          type: 'invalid-number-type',
          message: { key: 'phone.invalidNumberType' },
        });
      }
    }
    return true;
  })
  .default({
    code: '+32',
    number: '',
  });

export default phoneSchema;
