import { useContext, useState } from 'react';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { deleteNotifications } from 'api/notifications/requests';

const useNotificationsToDelete = (fetchNotifications: () => Promise<void>) => {
  const [notificationsToDelete, setNotificationsToDelete] = useState<string[]>([]);

  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const selectNotificationToDelete = (id: string) => {
    setNotificationsToDelete((prev) =>
      prev.some((notificationId) => notificationId === id)
        ? prev.filter((notificationId) => notificationId !== id)
        : [...prev, id],
    );
  };

  const deleteSelectedNotifications = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'notificationsDeleteSelected',
      onSubmit: () => deleteNotifications({ ids: notificationsToDelete.join(',') }),
      onSuccess: async () => {
        await fetchNotifications();
        setNotificationsToDelete([]);
      },
    });

  return {
    notificationsToDelete,
    selectNotificationToDelete,
    deleteSelectedNotifications,
  };
};

export default useNotificationsToDelete;
