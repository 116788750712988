import { SelectOption } from 'components/inputs/Select/types';
import { NotificationType } from './types';

export const NOTIFICATIONS_FILTERS = [
  'RentalDeposit',
  'FireInsurance',
  'LegalAssistance',
  'RentalContract',
  'MyMove',
  'Changes',
] as const;

export const notificationTypeOptions: SelectOption<NotificationType>[] = [
  {
    label: 'Action Required',
    value: 'actionRequired',
    translationKey: 'notificationTypes.ActionRequired',
  },
  { label: 'No Action', value: 'noAction', translationKey: 'notificationTypes.NoAction' },
];
