import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import transformName from 'utils/transformName';
import { deactivateUser, reactivateUser } from 'api/users/requests';
import { FetchedUserData } from 'api/users/types';

interface Props {
  user: FetchedUserData;
  fetchData: () => Promise<void>;
}

const UserViewHeader = ({ user, fetchData }: Props) => {
  const { userId } = useContext(AccountContext);
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const userName = transformName(user);

  const onDeactivate = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'deactivateUser',
      onSubmit: () => deactivateUser(user.id),
      onSuccess: fetchData,
      translationValues: { userName },
    });

  const onReactivate = () =>
    setUpAndOpenConfirmationModal({
      type: 'confirmation',
      translationKey: 'reactivateUser',
      onSubmit: () => reactivateUser(user.id),
      onSuccess: fetchData,
      translationValues: { userName },
    });

  return (
    <Box sx={({ mixins }) => ({ ...mixins.entityViewHeader, mb: 0, minHeight: 'unset' })}>
      <Box display="flex" alignItems="center" gap={1} mr="auto">
        <Typography variant="h4">{userName}</Typography>
      </Box>
      <Button
        onClick={() => navigate(routes.userEdition(user.id))}
        role="secondary"
        size="small"
        disabled={!user.accountEnabled}
      >
        {t('edit')}
      </Button>
      {user.accountEnabled ? (
        <Button
          onClick={onDeactivate}
          role="unsafe"
          variant="outlined"
          size="small"
          disabled={user.id === userId}
        >
          {t('deactivate')}
        </Button>
      ) : (
        <Button onClick={onReactivate} size="small" disabled={user.id === userId}>
          {t('reactivate')}
        </Button>
      )}
    </Box>
  );
};

export default UserViewHeader;
