import { Theme } from '@mui/material';
import { MixinsOptions } from '@mui/material/styles/createMixins';
import { ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT } from 'Layout/styles';
import { DossierStatus } from 'api/dossier/types';
import palette from './palette';

const PHOTO_HEIGHT = '3rem';
const PHOTO_WIDTH = '3rem';

export const ROUTE_TABS_HEIGHT = 60;

const DRAWER_OPENED_WIDTH = 230;
const DRAWER_CLOSED_WIDTH = 60;

export default (theme: Theme): MixinsOptions => ({
  centered: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  },
  tabsWrapper: {
    borderBottom: 1,
    borderColor: palette.divider,
  },
  viewTabsWrapper: {
    mb: 3,
    position: 'sticky',
    top: 0,
    gap: 1.5,
    backgroundColor: palette.background.default,
    zIndex: 1001,
  },
  openedDrawer: {
    width: DRAWER_OPENED_WIDTH,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    border: {
      xs: 0,
      [ALWAYS_VISIBLE_SIDEBAR_BREAKPOINT]: `1px solid ${palette.other.border}`,
    },
    borderRadius: 0,
  },
  closedDrawer: {
    width: DRAWER_CLOSED_WIDTH,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: 0,
  },
  toolbar: {
    minHeight: 80,
    paddingBottom: '8px',
  },
  headerIconButton: {
    borderColor: palette.grey[300],
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: palette.grey[0],
    color: palette.grey[500],
    boxShadow: ' 2px 3px 6px 1px #F2F2F2C9',
  },
  roundedSearch: {
    backgroundColor: palette.grey[0],
    borderRadius: '36px',
    color: palette.grey[500],
    boxShadow: '2px 3px 6px 1px #F2F2F2C9',
  },
  modalFooter: {
    px: 3,
    py: 2,
  },
  estatePhoto: {
    width: PHOTO_WIDTH,
    height: PHOTO_HEIGHT,
  },
  tableChip: {
    py: 1.75,
    px: 1,
    gap: 0.5,
    cursor: 'pointer',
    borderRadius: 2,
    color: palette.primary.main,
    borderColor: palette.other.borderDark,
    borderWidth: '1px',
    bgcolor: palette.grey.A50,
  },
  chip: {
    py: 1.75,
    px: 1,
    gap: 0.5,
    '&&:hover': {
      bgcolor: palette.primary.light,
      color: palette.common.white,
    },
  },
  currentChip: {
    py: 1.75,
    px: 1,
    gap: 0.5,
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    '&&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.dark,
    },
  },
  hideScroll: {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  scrollableCardContent: (
    xs = 'unset',
    lg = `calc(100vh - (${theme.mixins.toolbar.minHeight}px + 140px))`,
  ) => ({
    maxHeight: { xs, lg },
    overflowY: 'auto',
  }),
  stickyCardWrapper: {
    mb: 3,
    gap: 3,
    flexFlow: { lg: 'nowrap' },
    '& > *:first-of-type': {
      position: { xs: 'inherit', lg: 'sticky' },
      top: 0,
      mb: 'auto',
    },
  },
  outlinedInput: {
    '&.MuiOutlinedInput-root': {
      borderRadius: '8px',
      '&:hover fieldset': {
        borderColor: `${palette.primary.main}`,
      },
      '&:focus-within fieldset': {
        borderColor: `${palette.primary.main}`,
        borderWidth: '2px',
      },
    },
  },
  sidebarIcon: { minWidth: '28px' },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    wordBreak: 'break-word',
    color: palette.primary.main,
    ':hover': { color: palette.secondary.main },
  },
  getProductStatusColor: (status) => {
    switch (status) {
      case 'Selected':
      case 'Registered':
      case 'Running':
        return palette.success.main;
      case 'Sent':
      case 'OrderSent':
      case 'Requested':
        return palette.other.yellow;
      case 'Rejected':
        return palette.error.main;
      default:
        return palette.grey[400];
    }
  },
  getDossierStatusColor: (status: DossierStatus) => {
    switch (status) {
      case 'Draft':
        return 'grey.400';
      case 'Running':
        return 'success.main';
      case 'Cancelled':
        return 'grey.500';
      case 'InTreatment':
        return 'secondary.main';
      case 'Migrated':
        return 'info';
      default:
        return 'grey.300';
    }
  },
  pageCard: {
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'divider',
    boxShadow: 'none',
    ':hover': {
      boxShadow: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  actionCard: {
    '.MuiCardActionArea-root': {
      a: {
        textDecoration: 'none',
        color: 'unset',
      },
    },
    '.MuiCardContent-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      '&:last-child': { pb: 2 },
    },
  },
  tablePageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
    mb: 3,
  },
  headerMenu: { top: '10px', '.MuiMenu-list': { py: 0 } },
  entityViewHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: palette.background.default,
    minHeight: { lg: ROUTE_TABS_HEIGHT },
    mb: { lg: 3 },
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
    zIndex: 1001,
  },
  actionCellStyles: {
    width: '5%',
    '&.MuiTableCell-sizeSmall': {
      px: 0.5,
    },
  },
  tableCellStyles: {
    '&.MuiTableCell-sizeSmall': {
      py: 1,
      color: palette.common.black,
    },
  },
  cardsGrid: {
    display: 'grid',
    gap: 3,
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
  },
  miniCardsGrid: {
    display: 'grid',
    gap: 1,
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 3,
    py: 1,
    px: 1.5,
  },
  cardRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    p: 2,
    '&:not(:last-of-type)': {
      borderBottom: theme.shape.border,
    },
  },
});
