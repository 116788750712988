import { gridClasses } from '@mui/x-data-grid';
import { ThemedStyle } from 'types/themedStyle';
import { TableCustomClasses } from './types';

const tableStyles: ThemedStyle = ({ palette, shape }) => ({
  border: shape.border,

  [`.${gridClasses['container--top']}::after`]: {
    display: 'none',
  },

  [`&:has(.${gridClasses.toolbarContainer})`]: {
    [`& .${gridClasses.columnHeaders}`]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },

  [`.${gridClasses.columnHeaderCheckbox}`]: {
    svg: {
      fill: palette.common.white,
    },
    [`.${gridClasses.checkboxInput}.Mui-disabled > svg`]: {
      fill: palette.grey[500],
    },
  },

  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },

  [`& .${gridClasses.columnHeader}`]: {
    color: palette.common.white,
    bgcolor: palette.primary.main,
    py: 0.5,
    fontSize: 14,
    height: 'unset',

    '&:focus-within': { outline: 'none' },
    '&--sortable:hover': {
      bgcolor: palette.primary.dark,
    },

    [`&:not(.${gridClasses['columnHeader--sorted']}):hover`]: {
      [`.${gridClasses.sortIcon}`]: {
        opacity: 1,
      },
    },

    [`.${gridClasses.iconButtonContainer}`]: {
      visibility: 'visible',
      width: 'auto',
      [`.${gridClasses.sortIcon}`]: {
        fill: palette.grey[200],
        opacity: 1,
        width: 16,
        height: 16,
      },
    },
  },

  [`& .${gridClasses.row}`]: {
    a: {
      textDecoration: 'none',
      color: 'unset',

      [`.${gridClasses.cell}`]: {
        height: '100%',
      },
    },

    [`&.${TableCustomClasses.RowGreyOut}`]: {
      [`.${gridClasses.cell}:not([data-field="actions"])`]: {
        opacity: 0.35,
      },
    },

    '&:hover, &:nth-of-type(even):hover': {
      bgcolor: palette.grey[200],
    },
    '&:nth-of-type(even)': {
      bgcolor: palette.other.evenRow,
    },
    '&.Mui-selected': {
      bgcolor: palette.grey[200],
      '&:hover': {
        bgcolor: palette.grey[300],
      },
    },
  },

  [`.${gridClasses.cell}`]: {
    display: 'flex',
    alignItems: 'center',
    py: 1,
    fontSize: 14,
    border: 'none',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',

    '&:focus-within': { outline: 'none' },
  },

  [`& .${gridClasses.footerContainer}`]: {
    borderTop: shape.border,
  },

  [`& .${gridClasses.overlay}`]: {
    fontSize: 14,
    color: 'grey.500',
  },
});

export default tableStyles;
