import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from '@mui/material';
import CardRow from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';
import { getCompositionList } from './utils';

const EstateCompositionCard = ({
  estateComposition,
}: {
  estateComposition: NonNullable<EstateViewInterface['data']['estateComposition']>;
}) => {
  const { i18n, t } = useTranslation(['common', 'enums']);

  const items = useMemo(
    () => getCompositionList(estateComposition),
    [estateComposition, i18n.language],
  );

  return items.length ? (
    <Card sx={({ mixins }) => mixins.pageCard} data-testid="compositionContainer">
      <CardRow title={t('pageCard.estate.composition')} asHeader />
      <Box sx={({ mixins }) => ({ ...mixins.miniCardsGrid, p: 1 })}>
        {items.map(({ name, count, isCustom }) => (
          <Card key={name} variant="outlined" sx={({ mixins }) => mixins.cardContent}>
            <Typography fontWeight={600} data-testid={`${name}-title`}>
              {isCustom ? name : t(`enums:estateComposition.${name}`, name)}
            </Typography>
            <Typography fontWeight={600} data-testid={`${name}-value`}>
              {count}
            </Typography>
          </Card>
        ))}
      </Box>
    </Card>
  ) : null;
};

export default EstateCompositionCard;
