import { Box, Typography } from '@mui/material';
import NotificationsList from 'modules/NotificationsList';
import PageTitle from 'components/PageTitle';

const Notifications = () => (
  <Box sx={({ mixins }) => mixins.tablePageWrapper}>
    <PageTitle page="notifications" />
    {/* TODO: Remove once module will be ready */}
    <Typography variant="h5" textAlign="center" mb={2}>
      Work in progress
    </Typography>
    <NotificationsList />
  </Box>
);

export default Notifications;
