import { useTranslation } from 'react-i18next';
import CardRow, { RowValues } from 'templates/CardRow';
import { EstateViewInterface } from 'api/estate/types';

const EstateTypeRow = ({
  type,
  subType,
}: Pick<EstateViewInterface['data'], 'type' | 'subType'>) => {
  const { t } = useTranslation(['formFields', 'enums']);
  if (type && subType) {
    return (
      <CardRow
        title={
          <RowValues
            asTitle
            primary={t('formFields:type')}
            secondary={t('formFields:subType')}
          />
        }
        value={
          <RowValues
            primary={t(`enums:estateType.${type}`)}
            secondary={t(`enums:estateSubtype.${subType}`)}
          />
        }
        testId="estateType"
      />
    );
  }

  if (type) {
    return (
      <CardRow
        title={t('formFields:type')}
        value={t(`enums:estateType.${type}`)}
        testId="estateType"
      />
    );
  }

  return null;
};

export default EstateTypeRow;
