import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { CheckIcon } from 'icons';
import CardRow from 'templates/CardRow';
import { formatDate } from 'utils/date';
import { EstateViewInterface } from 'api/estate/types';
import EpcEnergyConsumptionRow from './EpcEnergyConsumptionRow';

const EstateEpcCard = ({ epc }: Pick<EstateViewInterface['data'], 'epc'>) => {
  const { t } = useTranslation(['formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('formFields:epc.self')} value={<CheckIcon />} />
      {epc?.type && (
        <CardRow
          title={t('formFields:epc.type')}
          value={t(`enums:estateEpcTypes.${epc.type}`)}
          testId="epcType"
        />
      )}
      <EpcEnergyConsumptionRow
        primaryEnergyConsumption={epc?.primaryEnergyConsumption ?? null}
        referenceEnergyConsumption={epc?.referenceEnergyConsumption ?? null}
      />
      {epc?.validUntil && (
        <CardRow
          title={t('formFields:epc.validUntil')}
          value={formatDate(epc.validUntil)}
          testId="validUntil"
        />
      )}
    </Card>
  );
};

export default EstateEpcCard;
