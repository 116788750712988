import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NaturalPersonFormContext } from 'contexts/personForm/naturalPersonFormContext';
import { AddEstateModal } from 'modules/ModalForm/AddEstateModal';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import {
  MAX_DOSSIER_PARTIES_COUNT,
  MAX_ESTATES_COUNT,
} from 'modules/Relation/static-data';
import {
  fetchEstateOptions,
  fetchPersonRelationOptions,
  fetchPersonRelationOptionsByType,
  getRelationsIds,
} from 'modules/Relation/utils';
import { FormContainer, FormSection } from 'templates/FormTemplates';
import PrivateRelationSection from './PrivateRelation';

const NaturalPersonAffiliationsStep = () => {
  const {
    personId,
    representedPartiesToPreview,
    setRepresentedPartiesToPreview,
    representativesToPreview,
    setRepresentativesToPreview,
    ownershipsToPreview,
    setOwnershipsToPreview,
  } = useContext(NaturalPersonFormContext);
  const { t } = useTranslation('common', {
    keyPrefix: 'stepWizard.naturalPerson.sections',
  });

  const representationsToFilter = useMemo(() => {
    const idsToFilter = getRelationsIds([
      ...representedPartiesToPreview,
      ...representativesToPreview,
    ]);

    return personId ? [personId, ...idsToFilter] : idsToFilter;
  }, [representedPartiesToPreview, representativesToPreview]);

  const ownershipsToFilter = useMemo(
    () => getRelationsIds(ownershipsToPreview),
    [ownershipsToPreview],
  );

  return (
    <FormContainer>
      <FormSection label={t('privateRelation')}>
        <PrivateRelationSection />
      </FormSection>
      <FormSection label={t('representativeOf')}>
        {/* 
        TODO: We should propose a new way of setting label, helperText and placeholder in AddRelation component and get rid of translationKey. 
        Current approach is too complex. It would be easier to have some options property here and set these fields if needed. 
        */}
        <AddRelation
          translationKey="naturalPersonRepresentedParty"
          withLabel={false}
          maxRelations={MAX_DOSSIER_PARTIES_COUNT}
          fetchOptions={fetchPersonRelationOptions}
          setRelationsToPreview={setRepresentedPartiesToPreview}
          selectedRelations={getRelationsIds(representedPartiesToPreview)}
          relationsToFilter={representationsToFilter}
          AddNewOptionModal={AddPersonModal}
        />
        <RelationPreviewList
          showIcons
          relationsToPreview={representedPartiesToPreview}
          setRelationsToPreview={setRepresentedPartiesToPreview}
        />
      </FormSection>
      <FormSection label={t('representedBy')}>
        <AddRelation
          translationKey="naturalPersonRepresentative"
          withLabel={false}
          maxRelations={MAX_DOSSIER_PARTIES_COUNT}
          fetchOptions={fetchPersonRelationOptionsByType('NaturalPerson')}
          setRelationsToPreview={setRepresentativesToPreview}
          selectedRelations={getRelationsIds(representativesToPreview)}
          relationsToFilter={representationsToFilter}
          AddNewOptionModal={AddNaturalPersonModal}
        />
        <RelationPreviewList
          showIcons
          relationsToPreview={representativesToPreview}
          setRelationsToPreview={setRepresentativesToPreview}
        />
      </FormSection>
      <FormSection label={t('ownerOf')}>
        <AddRelation
          translationKey="estate"
          withLabel={false}
          maxRelations={MAX_ESTATES_COUNT}
          fetchOptions={fetchEstateOptions}
          setRelationsToPreview={setOwnershipsToPreview}
          relationsToFilter={ownershipsToFilter}
          AddNewOptionModal={AddEstateModal}
        />
        <RelationPreviewList
          showIcons
          relationsToPreview={ownershipsToPreview}
          setRelationsToPreview={setOwnershipsToPreview}
        />
      </FormSection>
    </FormContainer>
  );
};

export default NaturalPersonAffiliationsStep;
