import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import PhoneInput from './PhoneInput';
import { ControlledPhoneInputProps } from './types';

const ControlledPhoneInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledPhoneInputProps<T, N>,
) => React.ReactElement = ({ name, error, warning, helperText, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, value, ...field } }) => (
      <PhoneInput
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        number={value?.number}
        code={value?.code}
        onNumberChange={(number, countryId) => onChange({ ...value, number, countryId })}
        onCountryChange={(countryId, code, number) =>
          onChange({ countryId, code, number })
        }
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...field}
        {...props}
      />
    )}
  />
);

export default ControlledPhoneInput;
