import { StepInterface } from 'templates/StepWizard/types';
import {
  EstateAdditionalStep,
  EstateCompositionStep,
  EstateDocumentsStep,
  EstateGeneralStep,
  EstateMediaStep,
} from './steps';

export const ESTATE_STEP_NAME = {
  general: 'general',
  additional: 'additional',
  media: 'media',
  composition: 'composition',
  documents: 'documents',
} as const;

export const ESTATE_STEPS: StepInterface[] = [
  { Component: EstateGeneralStep, name: ESTATE_STEP_NAME.general, required: true },
  { Component: EstateAdditionalStep, name: ESTATE_STEP_NAME.additional },
  { Component: EstateMediaStep, name: ESTATE_STEP_NAME.media },
  { Component: EstateCompositionStep, name: ESTATE_STEP_NAME.composition },
  { Component: EstateDocumentsStep, name: ESTATE_STEP_NAME.documents },
];
