import { Navigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useLoader from 'hooks/useLoader';
import UserForm from 'modules/UserForm';
import routes from 'router/routes';
import FullPageCard from 'templates/FullPageCard';
import { getUserConfig } from 'pages/Users/Details/utils';

const UserEdition = () => {
  const { userId } = useParams();
  if (!userId) return null;

  const { data, Loader } = useLoader(getUserConfig(userId));

  return (
    <Loader>
      {data?.accountEnabled ? (
        <FullPageCard shrinkHeight>
          <Box p={2.5}>
            <UserForm defaultValues={data} mode="edition" userId={data.id} />
          </Box>
        </FullPageCard>
      ) : (
        <Navigate to={routes.user(userId)} />
      )}
    </Loader>
  );
};

export default UserEdition;
