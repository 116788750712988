import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EAN_NUMBER_START } from 'formData/estate/details/eanNumber/static-data';
import { trimStartMask } from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { ControlledCheckbox, ControlledMaskedInput } from 'components/inputs';

const GasField = () => {
  const { t } = useTranslation('formFields');

  const { setValue, getValues, registerController, trigger } =
    useFormMethods<EstateFormInterface>();

  const updateCheckbox = () => {
    if (!getValues('estateDetails.hasGas')) {
      setValue('estateDetails.hasGas', true);
    }
  };

  const onEanNumberChange = () => {
    updateCheckbox();
    trigger('estateDetails.electricity.eanNumber');
  };

  const onMeterNumberChange = () => {
    updateCheckbox();
    trigger([
      'estateDetails.electricity.meterNumber',
      'estateDetails.electricity.meterNumber2',
    ]);
  };

  return (
    <Grid item container xs={12}>
      <Grid
        item
        container
        sx={({ shape }) => ({ border: shape.border, borderRadius: 2, padding: 2 })}
        rowGap={2}
      >
        <ControlledCheckbox
          xs={12}
          {...registerController('estateDetails.hasGas')}
          label={t('gas.self')}
        />
        <Grid item container xs={12} spacing={2}>
          <ControlledMaskedInput
            md={6}
            xs={12}
            {...registerController('estateDetails.gas.eanNumber')}
            label={t('gas.eanNumber')}
            mask={new Array(18).fill(/\d/)}
            onChange={onEanNumberChange}
            placeholder={`${EAN_NUMBER_START}1234567890123`}
          />
          <ControlledMaskedInput
            md={6}
            xs={12}
            {...registerController('estateDetails.gas.meterNumber')}
            {...trimStartMask}
            onChange={onMeterNumberChange}
            label={t('gas.meterNumber')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GasField;
