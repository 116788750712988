import { StepInterface } from 'templates/StepWizard/types';
import {
  LegalPersonAffiliationsStep,
  LegalPersonDocumentsStep,
  LegalPersonGeneralStep,
} from './steps';

export const LEGAL_PERSON_STEP_NAME = {
  general: 'general',
  affiliations: 'affiliations',
  documents: 'documents',
} as const;

export const LEGAL_PERSON_STEPS: StepInterface[] = [
  {
    Component: LegalPersonGeneralStep,
    name: LEGAL_PERSON_STEP_NAME.general,
    required: true,
  },
  { Component: LegalPersonAffiliationsStep, name: LEGAL_PERSON_STEP_NAME.affiliations },
  { Component: LegalPersonDocumentsStep, name: LEGAL_PERSON_STEP_NAME.documents },
];
