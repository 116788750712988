import { paymentSchema } from 'formData/common/schema';
import { TKey } from 'i18next';
import yup, { numberSchema } from 'utils/yup';
import { RentalDuration } from 'api/dossier/types';
import contractSigningDate from './contractSigningDate/schema';
import depositAmountSchema from './depositAmount/schema';
import endDateSchema from './endDate/schema';
import entryDateSchema from './entryDate/schema';
import taxPayableByTenantSchema from './taxPayableByTenant/schema';

const rentalConditionsSchema = yup.object({
  rentType: yup.string<TKey<'enums', 'rentType'>>().default('Habitation'),
  contractSigningDate: contractSigningDate.default(null),
  entryDate: entryDateSchema.default(null),
  duration: yup.string<RentalDuration>().nullable().default(null),
  durationNumberOfMonths: yup
    .number()
    .nullable()
    .default(null)
    .label('durationNumberOfMonths')
    .when('duration', {
      is: (value: TKey<'enums', 'rentalDossierDuration'>) => value === 'Other',
      then: (schema) => schema.required().positive(),
      otherwise: (schema) => schema.transform(() => null),
    }),
  endDate: endDateSchema.default(null),
  rentFrequency: yup.string<TKey<'enums', 'rentFrequency'>>().nullable().default(null),
  rentalPrice: paymentSchema
    .shape({
      value: numberSchema.label('rentalPrice').nullable().default(null).positive(),
    })
    .default(null),
  numberOfMonthlyRent: yup
    .string<TKey<'enums', 'dossierNumberOfMonthlyRent'>>()
    .nullable()
    .default(null),
  depositAmount: depositAmountSchema.default(null),
  charges: paymentSchema.default(null),
  isTaxPayableByTenant: yup.boolean().nullable().default(false),
  taxPayableByTenant: taxPayableByTenantSchema.nullable().default(null),
  isVatRegimeApplicable: yup.boolean().nullable().default(false),
  isAdditionalVatRegimeApplicable: yup.boolean().nullable().default(false),
});

export default rentalConditionsSchema;
