import { useFormContext } from 'react-hook-form';
import { ChangeStepHandler } from 'templates/StepWizard/types';
import { DossierFormInterface, DossierStepName } from '../types';
import useDossierStepsErrors from './useDossierStepsErrors';
import useSubmitDossierForm from './useSubmitDossierForm';

interface Props {
  stepsIndex: Record<DossierStepName, number>;
}

const useDossierSteps = ({ stepsIndex }: Props) => {
  const stepsErrors = useDossierStepsErrors(stepsIndex);
  const submitForm = useSubmitDossierForm();

  const { handleSubmit } = useFormContext<DossierFormInterface>();
  const changeStep: ChangeStepHandler = (e, changeStep, currentStep) => {
    switch (currentStep) {
      case stepsIndex.checklist:
        return changeStep();
      default:
        return handleSubmit(changeStep)(e);
    }
  };

  return {
    stepsErrors,
    onNext: changeStep,
    onLabel: changeStep,
    onBack: changeStep,
    onSave: handleSubmit(submitForm),
  };
};

export default useDossierSteps;
