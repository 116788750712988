import { UserPermissions } from 'consts/permissions';
import { TranslatedOption } from 'formData/types';
import i18n from 'i18n';
import routes from 'router/routes';
import { BoldCell } from 'templates/Table/Cells';
import { TableAction, TableColumn } from 'templates/Table/types';
import hasPermission from 'utils/hasPermission';
import { transformName } from 'utils/transformName/transformName';
import { deactivateUser, reactivateUser } from 'api/users/requests';
import { FetchedUserData } from 'api/users/types';

export const getLinkToUser = (id: string) => routes.user(id);

export const usersColumns: (
  roles: TranslatedOption[],
) => TableColumn<FetchedUserData, 'users'>[] = (roles) => [
  {
    field: 'name',
    renderCell: BoldCell,
    valueFormatter: (_value, row) => transformName(row),
    flex: 3,
    filterable: true,
  },
  {
    field: 'role',
    flex: 2,
    translateValue: (value) =>
      value ? i18n.t(`enums:userRoles.${value}`, value) : value,
    filterable: true,
    filterType: 'select',
    filterOptions: roles,
  },
  { field: 'email', flex: 3, filterable: true },
  {
    field: 'language',
    flex: 2,
    translateValue: (value) => (value ? i18n.t(`enums:language.${value}`, value) : value),
  },
];

export const getUsersActions = (
  user: FetchedUserData,
  loggedUserId: string,
): TableAction<'users'>[] => [
  user.accountEnabled
    ? {
        name: 'deactivate',
        hidden: !hasPermission(UserPermissions.Write),
        disabled: user.id === loggedUserId,
        onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
          setUpAndOpenConfirmationModal({
            translationKey: 'deactivateUser',
            translationValues: { userName: transformName(user) },
            onSubmit: () => deactivateUser(user.id),
            onSuccess: updateRows,
          });
        },
      }
    : {
        name: 'reactivate',
        hidden: !hasPermission(UserPermissions.Write),
        disabled: user.id === loggedUserId,
        onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
          setUpAndOpenConfirmationModal({
            type: 'confirmation',
            translationKey: 'reactivateUser',
            translationValues: { userName: transformName(user) },
            onSubmit: () => reactivateUser(user.id),
            onSuccess: updateRows,
          });
        },
      },
];
