import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { UNITS } from 'consts/units';
import CardRow from 'templates/CardRow';
import { formatDate } from 'utils/date';
import { EstateViewInterface } from 'api/estate/types';
import EstateSyndicRow from './EstateSyndicRow';

const EstateAdditionalDataCard = ({
  data: {
    habitableSurface,
    managementType,
    smokeDetectors,
    isFurnished,
    conformity,
    hasSyndic,
    syndic,
  },
}: Pick<EstateViewInterface, 'data'>) => {
  const { t } = useTranslation(['common', 'formFields', 'enums']);

  return (
    <Card sx={({ mixins }) => mixins.pageCard}>
      <CardRow asHeader title={t('pageCard.estate.additionalInfo')} />
      {habitableSurface && (
        <CardRow
          title={t('formFields:habitableSurface')}
          value={`${habitableSurface} ${UNITS.squareMeter}`}
          testId="habitableSurface"
        />
      )}
      {managementType && (
        <CardRow
          title={t('formFields:managementType')}
          value={t(`enums:estateManagementTypes.${managementType}`)}
          testId="managementType"
        />
      )}
      {smokeDetectors !== null && (
        <CardRow
          title={t('formFields:smokeDetectors')}
          value={smokeDetectors}
          testId="smokeDetectors"
        />
      )}
      {isFurnished && (
        <CardRow
          title={t('formFields:isFurnished')}
          value={t('yes')}
          testId="isFurnishedCheck"
        />
      )}
      {conformity && (
        <CardRow
          title={t('formFields:conformity')}
          value={formatDate(conformity)}
          testId="conformity"
        />
      )}
      <EstateSyndicRow hasSyndic={hasSyndic} syndic={syndic} />
    </Card>
  );
};

export default EstateAdditionalDataCard;
