import { FetchedTableData } from 'templates/Table/types';
import { transformAddress } from 'utils/transformAddressResponse';
import {
  DossierResponse,
  DossierUpdateInterface,
  DossierViewInterface,
  FetchedDossierData,
  RentalConditions,
  Residents,
  ResidentsForm,
} from './types';

export const transformPutData = ({
  tenants,
  landlords,
  ownReference,
  status,
  responsibleUsers,
}: DossierUpdateInterface): DossierUpdateInterface => ({
  status,
  ownReference: ownReference || null,
  responsibleUsers,
  landlords: landlords.map(({ personId, type }) => ({ personId, type })),
  tenants: tenants.map(({ personId, type }) => ({ personId, type })),
});

export const transformResidents = ({
  childrenDateOfBirth,
  numberOfChildren,
  ...data
}: ResidentsForm): Residents => ({
  ...data,
  numberOfChildren,
  childrenDateOfBirth: childrenDateOfBirth?.length
    ? childrenDateOfBirth
        .map(({ dateOfBirth }) => dateOfBirth)
        .filter((dateOfBirth): dateOfBirth is string => !!dateOfBirth)
    : null,
});

export const transformDossierViewResponse = (
  response: DossierViewInterface,
): DossierViewInterface => ({
  ...response,
  estate: { ...response.estate, address: transformAddress(response.estate.address) },
  tenants: response.tenants.map((tenant) => ({
    ...tenant,
    address: tenant.address ?? '',
  })),
  landlords: response.landlords.map((landlord) => ({
    ...landlord,
    address: landlord.address ?? '',
  })),
});

const transformDossierResponse = (response: DossierResponse): FetchedDossierData => ({
  ...response,
  estate: {
    ...response.estate,
    address: response.estate.address ?? '',
    city: response.estate.city ?? '',
    postCode: response.estate.postCode ?? '',
  },
  tenants: response.tenants.map((tenant) => ({
    ...tenant,
    address: tenant.address ?? '',
  })),
  landlords: response.landlords.map((landlord) => ({
    ...landlord,
    address: landlord.address ?? '',
  })),
});

export const transformDossierListResponse = ({
  meta,
  data,
}: FetchedTableData<DossierResponse>) => ({
  meta,
  data: data.map(transformDossierResponse),
});

export const transformRentalConditions = ({
  rentType,
  isVatRegimeApplicable,
  isAdditionalVatRegimeApplicable,
  isTaxPayableByTenant,
  ...rentalConditions
}: RentalConditions): RentalConditions => ({
  ...rentalConditions,
  rentType,
  isTaxPayableByTenant: rentType === 'Habitation' ? false : isTaxPayableByTenant,
  isVatRegimeApplicable: rentType !== 'Commercial' ? null : isVatRegimeApplicable,
  isAdditionalVatRegimeApplicable:
    rentType !== 'Commercial' ? null : isAdditionalVatRegimeApplicable,
});
