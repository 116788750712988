import {
  GridColDef,
  GridRowClassNameParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { TranslatedOption } from 'formData/types';
import { ConfirmationProps } from 'hooks/useConfirmationModal/types';
import { CustomNavigation } from 'hooks/useCustomNavigate/types';
import { TKey } from 'i18next';
import { Modify } from 'types/modify';
import { ApiReturn, UrlParams } from 'api/types';

export interface MetaData {
  count: number;
  offset: number;
  limit: number;
  total: number;
}

export interface ListSearchParams {
  limit: number;
  offset: number;
  filters: Record<string, string>;
  /** @example `${field}:${order}` */
  sort?: string;
}

export type TableSearchParams = Record<string, string>;

export type TableTranslationKey = TKey<
  'table',
  undefined,
  false,
  'defaultEmpty' | 'header' | 'filters' | 'pagination'
>;

export interface TableColumn<
  TRow extends GridValidRowModel,
  Key extends TableTranslationKey,
> extends Omit<GridColDef<TRow>, 'headerName' | 'flex'> {
  field: TKey<'table', `${Key}.columns`> | 'actions';
  flex: number;
  translateValue?: (value: string, row: TRow) => string;
  filterName?: string;
  filterType?: 'string' | 'date' | 'dateRange' | 'select';
  filterOptions?: TranslatedOption[];
}

export type TranslatedTableColumn<
  TRow extends GridValidRowModel = GridValidRowModel,
  Key extends TableTranslationKey = TableTranslationKey,
> = Modify<
  TableColumn<TRow, Key>,
  {
    field: string;
    headerName: string;
    filterLabel: string;
  }
>;

export interface TableFilter {
  name: string;
  value: string;
}

export interface FetchedTableData<T> {
  data: T[];
  meta: MetaData;
}

interface TableActionClickProps {
  navigate: CustomNavigation;
  updateRows: () => Promise<void>;
  setUpAndOpenConfirmationModal: (props: ConfirmationProps) => void;
}

export interface TableAction<Key extends TableTranslationKey> {
  name: TKey<'table', `${Key}.actions`>;
  onClick: (props: TableActionClickProps) => void;
  hidden?: boolean;
  disabled?: boolean;
  reason?: TKey<'table', `${Key}.tooltips`>;
}

export interface TableSidebarFilterComponentProps {
  filterName: string;
  options?: { name: string; label: string }[];
  updateFilter: (filterName: string, value: string) => void;
}

export interface TableSidebarFilter<
  Key extends TableTranslationKey = TableTranslationKey,
> {
  name: TKey<'table', `${Key}.filters`>;
  filterName: string;
  options?: { name: string; label: string }[];
  Component: (props: TableSidebarFilterComponentProps) => JSX.Element | null;
}

export interface TableToolbarProps<
  TRow extends GridValidRowModel,
  Key extends TableTranslationKey = TableTranslationKey,
> {
  filters?: TableSidebarFilter<Key>[];
  Component?: () => JSX.Element | null;
  rowSelection?: {
    handleSelectedRows: (
      rowsIds: GridRowSelectionModel,
      config: {
        rows: TRow[];
        setUpAndOpenConfirmationModal: (props: ConfirmationProps) => void;
        updateRows: () => Promise<void>;
      },
    ) => void;
    disableMultipleRowSelection?: boolean;
    hideButton?: boolean;
    buttonRole?: 'unsafe' | 'cta';
    buttonLabel?: TKey<'table'>;
    onRowSelection?: (row?: TRow) => void;
    isRowSelectable?: (params: GridRowParams<TRow>) => boolean;
  };
}

export type GetTableList<TRow extends GridValidRowModel> = (
  params: UrlParams,
) => Promise<ApiReturn<FetchedTableData<TRow>>>;

export interface TableProps<
  TRow extends GridValidRowModel,
  Key extends TableTranslationKey = TableTranslationKey,
> {
  tKey: Key;
  get: GetTableList<TRow>;
  columns: TableColumn<TRow, Key>[];
  withUrlSearchParams?: boolean;
  getActions?: (row: TRow) => TableAction<Key>[];
  rowRedirectionLink?: (id: string, row: TRow) => string;
  toolbarProps?: TableToolbarProps<TRow>;
  /** @default id */
  rowIdKey?: keyof TRow;
  rowsPerPage?: number[];
  getRowClassName?: (params: GridRowClassNameParams<TRow>) => TableCustomClasses | '';
}

export enum TableCustomClasses {
  RowGreyOut = 'row-greyOut',
}
